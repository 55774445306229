import DefaultServiceProviderImage from "img/ic_no_service_provider.svg";

import './search-dealer.modal.scss';

export default function SearchDealerModal($scope, $routeParams, $q, $t, Notification, ToggleLD, api, close) {
  'ngInject';

  $scope.searchDealer = {
    query: ''
  };

  $scope.autocomplete = (request) => {
    const deferred = $q.defer();
    const query = {
      email: request,
      notDealers: true,
      orgId: $routeParams.orgId
    };

    api.autocomplete(query,
      response => {
        response
          .filter(item => !item.avatar)
          .forEach(item => item.avatar = DefaultServiceProviderImage)
        deferred.resolve(response)
      },
      error => deferred.reject(error));

    return deferred.promise;
  }

  $scope.displayDealer = item => {
    return item ? item.email : '';
  }

  $scope.ok = (form) => {
    if (form.$valid) {
      if (!$scope.searchDealer.dealer) {
        Notification.error($t('shared.modals.search_dealer.select_dealer_error_message'));
        return;
      }

      ToggleLD.show();
      api.addDealer({id: $scope.searchDealer.dealer.id, path: 'dealers'}, {},
        response => {
          Notification.success($t('organisation_owner.service_providers.dealers.add_to_dealers_list_success_message'));
          ToggleLD.hide();
          close(true);
        },
        error => {
          Notification.error(error.data.message || $t('organisation_owner.service_providers.dealers.update_dealer_list_error_message'));
          ToggleLD.hide();
        });
    }
  }

  $scope.close = () => {
    close(false);
  }
}