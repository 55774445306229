export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/organisation/:orgId/applications', 'organisation.applications')
    .when('/organisation/:orgId/applications/marketplace', 'organisation.marketplace')
    .when('/organisation/:orgId/applications/:id', 'organisation.application-details')
    .when('/organisation/:orgId/applications/:id/installed', 'organisation.view-application')

    .within('organisation')

    .segment('applications', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.applications'
      }],
      template: '<organisation-owner-installed-application-page></organisationOwnerDealersPage>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-installed-applications.module" */ "./installed/organisation-owner-installed-applications.module");
      }
    })
    .segment('marketplace', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'organisation_owner.breadcrumb.applications',
          url: '/organisation/:orgId/applications',
          params: {
            orgId: ':orgId'
          }
        },
        {
          label: 'organisation_owner.breadcrumb.application_marketplace',
        },
      ],
      template: '<organisation-owner-applications-marketplace-page></organisation-owner-applications-marketplace-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-applications-marketplace.module" */ "./marketplace/organisation-owner-applications-marketplace.module");
      }
    })
    .segment('application-details', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'organisation_owner.breadcrumb.applications',
          url: '/organisation/:orgId/applications',
          params: {
            orgId: ':orgId'
          }
        },
        {
          label: 'organisation_owner.breadcrumb.application_marketplace',
          url: '/organisation/:orgId/applications/marketplace',
          params: {
            orgId: ':orgId'
          }
        },
        {
          label: 'organisation_owner.breadcrumb.application_details'
        }
      ],
      template: '<organisation-owner-application-details-page></organisation-owner-application-details-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-application-details.module" */ "./details/organisation-owner-application-details.module");
      }
    })
    .segment('view-application', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'organisation_owner.breadcrumb.applications',
          url: '/organisation/:orgId/applications',
          params: {
            orgId: ':orgId'
          }
        },
        {
          label: 'organisation_owner.breadcrumb.application_details'
        }
      ],
      template: '<organisation-owner-application-view-page></organisation-owner-application-view-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-application-view.module" */ "./view/organisation-owner-application-view.module");
      }
    });

}
