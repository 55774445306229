export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/organisation/:orgId/bicycles', 'organisation.bicycles-list')
    .when('/organisation/:orgId/bicycles/:id', 'organisation.bicycle-info')
    .when('/organisation/:orgId/bicycle-groups', 'organisation.groups-list')
    .when('/organisation/:orgId/bicycle-groups/:groupId/bicycles', 'organisation.group-bicycles')

    .within('organisation')

    .segment('bicycles-list', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.bicycles'
      }],
      template: '<organisation-owner-bicycles-list-page></organisation-owner-bicycles-list-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-bicycles-list.module" */ "./list/organisation-owner-bicycles-list.module");
      }
    })
    .segment('bicycle-info', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'organisation_owner.breadcrumb.bicycles',
          url: '/organisation/:orgId/bicycles',
          params: {
            orgId: ':orgId'
          }
        },
        {
          label: 'organisation_owner.breadcrumb.bicycle_info'
        }
      ],
      template: '<organisation-owner-bicycle-info-page></organisation-owner-bicycle-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-bicycle-info.module" */ "./info/organisation-owner-bicycle-info.module");
      }
    })
    .segment('groups-list', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.bicycles'
      }],
      template: '<organisation-owner-bicycle-groups-page></organisation-owner-bicycle-groups-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-bicycle-groups.module" */ "./groups/organisation-owner-bicycle-groups.module");
      }
    })
    .segment('group-bicycles', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'organisation_owner.breadcrumb.bicycles',
          url: '/organisation/:orgId/bicycle-groups',
          params: {
            orgId: ':orgId'
          }
        },
        {
          label: 'organisation_owner.breadcrumb.bicycle_group'
        }
      ],
      template: '<organisation-owner-group-bicycles-list-page></organisation-owner-group-bicycles-list-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-group-bicycles-list.module" */ "./group-bicycles-list/organisation-owner-group-bicycles-list.module");
      }
    })

}
