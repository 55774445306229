import angular from "angular";

import AutocompleteComponent from "./directives/autocomplete/autocomplete.component";
import ButtonComponent from "./directives/button/button.component";
import CheckboxComponent from "./directives/checkbox/checkbox.component";
import ColorPickerComponent from "./directives/color-picker/color-picker.component";
import DatePickerComponent from "./directives/date-picker/date-picker.component";
import DropdownListComponent from "./directives/dropdown-list/dropdown-list.component";
import IframeDimensionsComponent from "./directives/iframe/iframe-dimensions.component";
import InputContainerComponent from "./directives/input-container/input-container.component";
import InputFilterComponent from "./directives/input-filter/input-filter.component";
import LabelComponent from "./directives/label/label.component";
import LinkComponent from "./directives/link/link.component";
import LocationAutocompleteComponent from "./directives/location-autocomplete/location-autocomplete.component";
import LocationFilterComponent from "./directives/location-filter/location-filter.component";
import MoreActionComponent from "./directives/more-action/more-action.component";
import MoreButtonComponent from "./directives/more-button/more-button.component";
import MultiselectFilterComponent from "./directives/multiselect-filter/multiselect-filter.component";
import OptionComponent from "./directives/option/option.component";
import PopoverComponent from "./directives/popover/popover.component";
import RadioButtonComponent from "./directives/radio-button/radio-button.component";
import RemoveAutocomplete from "./directives/remove-autocomplete/remove-autocomplete.component";
import RemoveGoogleAnalytics from "./directives/remove-ga/remove-ga.component";
import RequiredLabelComponent from "./directives/required-label/required-label.component";
import SimpleScrollbar from "./directives/scroll/simple-scrollbar.component";
import SelectComponent from "./directives/select/select.component";

import BackBtnComponent from "./components/back-btn/back-btn.component";
import BreadcrumbComponent from "./components/breadcrumb/breadcrumb.component";
import CalendarComponent from "./components/calendar/calendar.component";
import ChatInputComponent from "./components/chat-input/chat-input.component";
import CollapsibleSectionComponent from "./components/collapsible-section/collapsible-section.component";
import ContentComponent from "./components/content/content.component";
import ContentViewComponent from "./components/content-view/content-view.component";
import EditBtnComponent from "./components/edit-btn/edit-btn.component";
import FormComponent from "./components/form/form.component";
import FormImageComponent from "./components/form-image/form-image.component";
import FormImagesListComponent from "./components/form-images-list/form-images-list.component";
import FormImagePaginationComponent from "./components/form-image-pagination/form-image-pagination.component";
import FormInfoComponent from "./components/form-info/form-info.component";
import GMapComponent from "./components/gmap/g-map.component";
import ImagesGalleryComponent from "./components/images-gallery/images-gallery.component";
import InfiniteScrollComponent from "./components/infinite-scroll/infinite-scroll.component";
import FormInputGroupComponent from "./components/input-group/input-group.component";
import InputStarsComponent from "./components/input-stars/input-stars.component";
import MainMenuComponent from "./components/main-menu/main-menu.component";
import MainMenuGlobalSearchItemComponent
  from "./components/main-menu-global-search-item/main-menu-global-search-item.component";
import MainMenuHelpItemComponent from "./components/main-menu-help-item/main-menu-help-item.component";
import MainMenuItemComponent from "./components/main-menu-item/main-menu-item.component";
import MainMenuNotificationsItemComponent
  from "./components/main-menu-notifications-item/main-menu-notifications-item.component";
import MainMenuProfileItemComponent from "./components/main-menu-profile-item/main-menu-profile-item.component";
import MenuComponent from "./components/menu/menu.component";
import ModalComponent from "./components/modal/modal.component";
import PageSizeComponent from "./components/page-size/page-size.component";
import PaginationComponent from "./components/pagination/pagination.component";
import PhoneNumberComponent from "./components/phone-number/phone-number.component";
import SideFormComponent from "./components/side-form/side-form.component";
import SideMenuComponent from "./components/side-menu/side-menu.component";
import SideMenuItemComponent from "./components/side-menu-item/side-menu-item.component";
import SideMenuSignOutItemComponent from "./components/side-menu-sign-out-item/side-menu-sign-out-item.component";
import SideMenuSignOutToAdminItemComponent from "./components/side-menu-sign-out-to-admin-item/side-menu-sign-out-to-admin-item.component";
import StepperComponent from "./components/stepper/stepper.component";
import StepperContentComponent from "./components/stepper-content/stepper-content.component";
import StepperItemComponent from "./components/stepper-item/stepper-item.component";
import SwitchComponent from "./components/switch/switch.component";
import TabComponent from "./components/tab/tab.component";
import TabsComponent from "./components/tabs/tabs.component";

import ZipCodeValidation from "./validations/address/zip-code.validation";
import CardCvcValidation from "./validations/payment/card-cvc.validation";
import CardExpiryDateValidation from "./validations/payment/card-expiry-date.validation";
import CardNameValidation from "./validations/payment/card-name.validation";
import CardNumberValidation from "./validations/payment/card-number.validation";
import PasswordMatchValidation from "./validations/security/password-match.validation";

import SecurityApi from "./resources/security/security.resource";

import ChatService from "./services/chat/chat.service"
import ChatWsService from "./services/chat/chat-ws.service"
import GMapConfig from "./services/gmap/gmap-config.service"
import HandlersService from "./services/handlers/hendlers-registry.service"
import I18nService from "./services/i18n/i18n.service"
import LocationService from "./services/location/location.service"
import ModalService from "./services/model/modal.service"
import ModalsService from "./modals/modals.service"
import NotificationsService from "./services/notifications/notifications.service"
import NotificationsWsService from "./services/notifications/notifications-ws.service"
import PageableService from "./services/pageable/pageable.service"
import PaymentSourceService from "./services/payment/payment-source.service"
import ResolutionService from "./services/resolution/resolution.service"
import ChangeRouteService from "./services/route/change-route.service"
import SideFormService from "./services/side-form/side-form.service"
import LocalStorageService from "./services/storage/local-storage.service"
import UploadService from "./services/upload/upload.service"

import ClearEmojiFilter from "./filters/clear-emiji.filter"
import XssFilter from "./filters/xss.filter"

import emojis from "./constants/emoji.constants";

export default angular.module("vt-shared.module", [])
  .directive("vuiAutocomplete", AutocompleteComponent)
  .directive("vuiButton", ButtonComponent)
  .directive("vuiCheckbox", CheckboxComponent)
  .directive("vuiColorPicker", ColorPickerComponent)
  .directive("vuiDatePicker", DatePickerComponent)
  .directive("vuiDropdownList", DropdownListComponent)
  .directive("vuiSetIframeDimensionsOnload", IframeDimensionsComponent)
  .directive("vuiInputContainer", InputContainerComponent)
  .directive("vuiInputFilter", InputFilterComponent)
  .directive("vuiLabel", LabelComponent)
  .directive("vuiLink", LinkComponent)
  .directive("vuiLocationAutocomplete", LocationAutocompleteComponent)
  .directive("vuiLocationFilter", LocationFilterComponent)
  .directive("vuiMoreAction", MoreActionComponent)
  .directive("vuiMoreButton", MoreButtonComponent)
  .directive("vuiMultiselectFilter", MultiselectFilterComponent)
  .directive("vuiOption", OptionComponent)
  .directive("vuiPopover", PopoverComponent)
  .directive("vuiRadioButton", RadioButtonComponent)
  .directive("vuiRmAutocomplete", RemoveAutocomplete)
  .directive("vuiRmGa", RemoveGoogleAnalytics)
  .directive("vuiRequiredLabel", RequiredLabelComponent)
  .directive("vuiSimpleScrollbar", SimpleScrollbar)
  .directive("vuiSelect", SelectComponent)
  .component("vuiBackBtn", BackBtnComponent)
  .component("vuiBreadcrumb", BreadcrumbComponent)
  .component("vuiCalendar", CalendarComponent)
  .component("vuiChatInput", ChatInputComponent)
  .component("vuiCollapsibleSection", CollapsibleSectionComponent)
  .component("vuiContent", ContentComponent)
  .component("vuiContentView", ContentViewComponent)
  .component("vuiEditBtn", EditBtnComponent)
  .component("vuiForm", FormComponent)
  .component("vuiFormImage", FormImageComponent)
  .component("vuiFormImagesList", FormImagesListComponent)
  .component("vuiFormInfo", FormInfoComponent)
  .component("vuiFormImagePagination", FormImagePaginationComponent)
  .component("vuiGMap", GMapComponent)
  .component("vuiImagesGallery", ImagesGalleryComponent)
  .component("vuiInfiniteScroll", InfiniteScrollComponent)
  .component("vuiInputGroup", FormInputGroupComponent)
  .component("vuiInputStars", InputStarsComponent)
  .component("vuiMainMenu", MainMenuComponent)
  .component("vuiMainMenuGlobalSearchItem", MainMenuGlobalSearchItemComponent)
  .component("vuiMainMenuHelpItem", MainMenuHelpItemComponent)
  .component("vuiMainMenuItem", MainMenuItemComponent)
  .component("vuiMainMenuNotificationsItem", MainMenuNotificationsItemComponent)
  .component("vuiMainMenuProfileItem", MainMenuProfileItemComponent)
  .component("vuiMenu", MenuComponent)
  .component("vuiModal", ModalComponent)
  .component("vuiPageSize", PageSizeComponent)
  .component("vuiPagination", PaginationComponent)
  .component("vuiPhoneNumberInput", PhoneNumberComponent)
  .component("vuiSideForm", SideFormComponent)
  .component("vuiSideMenu", SideMenuComponent)
  .component("vuiSideMenuItem", SideMenuItemComponent)
  .component("vuiSideMenuSignOutItem", SideMenuSignOutItemComponent)
  .component("vuiSideMenuSignOutToAdminItem", SideMenuSignOutToAdminItemComponent)
  .component("vuiStepper", StepperComponent)
  .component("vuiStepperContent", StepperContentComponent)
  .component("vuiStepperItem", StepperItemComponent)
  .component("vuiSwitch", SwitchComponent)
  .component("vuiTab", TabComponent)
  .component("vuiTabs", TabsComponent)

  .directive("vuiZipCodeValidation", ZipCodeValidation)
  .directive("vuiCardCvcValidation", CardCvcValidation)
  .directive("vuiCardExpiryDateValidation", CardExpiryDateValidation)
  .directive("vuiCardNameValidation", CardNameValidation)
  .directive("vuiCardNumberValidation", CardNumberValidation)
  .directive("vuiPasswordMatchValidation", PasswordMatchValidation)

  .service("SecurityApi", SecurityApi)

  .provider("vuiChatService", ChatService)
  .provider("vuiChatWsService", ChatWsService)
  .provider("vuiModals", ModalsService)
  .provider("gMapConfig", GMapConfig)
  .provider("$handlers", HandlersService)
  .provider("$t", I18nService)
  .provider("vuiLocation", LocationService)
  .provider("vuiModalService", ModalService)
  .provider("vuiNotificationsService", NotificationsService)
  .provider("vuiNotificationsWsService", NotificationsWsService)
  .provider("vuiPageable", PageableService)
  .provider("vuiPaymentSourceService", PaymentSourceService)
  .provider("vuiResolutionService", ResolutionService)
  .provider("vuiChangeRoute", ChangeRouteService)
  .provider("vuiSideForm", SideFormService)
  .provider("vuiLocalStorage", LocalStorageService)
  .provider("vuiUpload", UploadService)

  .filter("vuiClearEmoji", ClearEmojiFilter)
  .filter("vuiXss", XssFilter)

  .constant("vuiEmojis", emojis);

