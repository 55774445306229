import './breadcrumb.component.scss';

export default {
  template: require('./breadcrumb.component.html'),
  transclude: true,
  controller: function ($routeSegment, $routeParams, HistoryService) {
    'ngInject';

    const self = this;

    self.getBreadcrumb = () => {
      const segment = $routeSegment.chain.findLast(segment => segment && segment.params && segment.params.breadcrumb);
      if (segment) {
        const breadcrumb = segment.params.breadcrumb;
        const lastUrl = breadcrumb.findLast(item => item.url);
        if (lastUrl) {
          self.prevUrl = HistoryService.getPrevUrl(getBackUrl(lastUrl));
        } else {
          self.prevUrl = undefined;
        }

        return breadcrumb;
      }

      return [];
    }

    self.onBack = () => HistoryService.onBack();

    const getBackUrl = (lastUrl) => {
      if (lastUrl) {
        let url = lastUrl.url
        if (lastUrl.params) {
          for (let key in lastUrl.params) {
            url = url.replaceAll(lastUrl.params[key], $routeParams[key])
          }
        }

        return url;
      }

      return undefined;
    }
  }
};
