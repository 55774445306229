import './confirmation-request-changes.modal.scss';

export default function ConfirmationRequestChangesModal($scope, close, details) {
  'ngInject';

  $scope.details = details;

  $scope.ok = () => {
    close(true);
  }

  $scope.close = () => {
    close(false);
  }
}