import "./update-organisation-info.component.scss";

import UploadAvatarDefaultImage from "img/upload-image.svg";

export default {
  template: require('./update-organisation-info.component.html'),
  bindings: {
    organisationInfo: '<vuiOrganisationInfo',
    locationResource: '<vuiLocationResource'
  },
  controller: function ($routeParams, vuiSideForm, OrganisationOwnerOrganisationsApi, ToggleLD, LocationService, Notification,
                        FileService, vuiModals, vuiUpload, $t) {
    'ngInject';

    const self = this;

    self.removeLocation = (location) => {
      const index = self.organisationInfo.locations.indexOf(location);
      if (index > -1) {
        self.organisationInfo.locations.splice(index, 1);
      }
    }

    self.addLocation = () => {
      self.organisationInfo.locations.push({})
    }

    self.onChangePhoneNumber = (phone, isValid) => {
      self.organisationInfo.contact.phone = phone;
      self.organisationInfo.contact.isValidPhone = isValid;
    };

    self.save = (form) => {
      if (form.$valid && self.organisationInfo.contact.isValidPhone) {
        const request = {
          name: self.organisationInfo.name,
          link: self.organisationInfo.link,
          image: self.organisationInfo.image.id ? self.organisationInfo.image : undefined,
          contact: {
            email: self.organisationInfo.contact.email,
            phone: self.organisationInfo.contact.phone
          },
          locations: []
        }

        for (let i = 0; i < self.organisationInfo.locations.length; i++) {
          const location = self.organisationInfo.locations[i];
          if (!location.address) {
            Notification.error($t('organisation_owner.update_organisation.address_validation_error'));
            return;
          }

          const error = LocationService.validateProfileAddress(location.address);
          if (error) {
            Notification.error(error);
            return;
          }

          request.locations.push({
            address: location.address.address,
            googleAddress: location.address.googleAddress,
            placeId: location.address.placeId,
            latitude: location.address.latitude,
            longitude: location.address.longitude,
            country: location.address.country,
            city: location.address.city,
            bounds: location.address.bounds,
            county: location.address.county,
            state: location.address.state,
            zip: location.address.zip,
            street: location.address.street,
            building: location.address.building,
            offset: location.address.offset,
            apartment: location.address.apartment
          });
        }

        ToggleLD.show();

        OrganisationOwnerOrganisationsApi.update({id: $routeParams.orgId}, request,
          response => {
            self.organisationInfo.setProfile(response);
            Notification.success($t('organisation_owner.update_organisation.update_organisation_info_success_message'));
            vuiSideForm.close('update-organisation-info');
            ToggleLD.hide();
          }, error => {
            ToggleLD.hide();
            Notification.error(error.data.message || $t('organisation_owner.update_organisation.update_organisation_info_error_message'));
          });
      }
    };

    self.chooseFile = (files, invalidFiles) => {
      let file;
      if (files[0]) {
        file = files[0];
      } else if (invalidFiles[0]) {
        file = invalidFiles[0];
      }

      if (!FileService.isValidFile(file)) {
        return;
      }

      vuiModals.cropImage(file, "organisation_owner.update_organisation.update_organisation_info_title",
        vuiUpload.organisationOwner($routeParams.orgId).uploadOrganisationImage,
        response => {
          Notification.success($t('organisation_owner.update_organisation.update_organisation_image_success_message'));
          self.organisationInfo.image = response;
        })
    };

    self.clearAvatar = () => {
      self.organisationInfo.image = {thumbnail: UploadAvatarDefaultImage};
    }

    self.close = () => {
      vuiSideForm.close('update-organisation-info');
    };
  }
};
