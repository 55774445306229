import './top-up-balance.modal.scss';

export default function TopUpBalanceModal($scope, $t, close, sources, currentSource, Notification) {
  'ngInject';

  $scope.topUpBalance = {
    amount: 100,
    sources: sources || [],
    currentSource: currentSource
  };

  $scope.close = () => {
    close();
  };

  $scope.ok = () => {
    if (!$scope.topUpBalance.currentSource) {
      Notification.error($t('shared.modals.top_up_balance.payment_source_required_error_message'));
      return;
    }

    close({
      paymentSource: $scope.topUpBalance.currentSource,
      amount: $scope.topUpBalance.amount,
      currency: "USD"
    });
  };

  $scope.addNewCard = () => {
    close({ new: true });
  };

}