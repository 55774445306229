export default function ModalsServiceProvider() {
  this.$get = (vuiModalService) => {
    'ngInject';

    function Modals() {
      const self = this;

      self.addBicycle = (types, loadBrands, checkSerialNumber, callback) => {
        vuiModalService.showModal({
          template: require('./add-bicycle/add-bicycle.modal.html'),
          controller: require('./add-bicycle/add-bicycle.modal').default,
          inputs: {
            types: types,
            loadBrands: loadBrands,
            checkSerialNumber: checkSerialNumber
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.afterCreateRequest = (callback) => {
        vuiModalService.showModal({
          template: require('./after-create-request/after-create-request.modal.html'),
          controller: require('./after-create-request/after-create-request.modal').default
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.bicyclePublicLink = (fakeId) => {
        vuiModalService.showModal({
          template: require('./bicycle-public-link/bicycle-public-link.modal.html'),
          controller: require('./bicycle-public-link/bicycle-public-link.modal').default,
          inputs: {
            fakeId: fakeId
          }
        }).then(modal => modal.close
          .then(result => {
          }));
      }

      self.cancelationRequestInfo = (config, callback) => {
        vuiModalService.showModal({
          template: require('./cancelation-info/cancelation-info.modal.html'),
          controller: require('./cancelation-info/cancelation-info.modal').default,
          inputs: {
            config: config
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.changeBicycleGroup = (groups, selectedGroup, callback) => {
        vuiModalService.showModal({
          template: require('./change-bicycle-group/change-bicycle-group.modal.html'),
          controller: require('./change-bicycle-group/change-bicycle-group.modal').default,
          inputs: {
            groups: groups,
            selectedGroup: selectedGroup
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.changePaymentSource = (sources, currentSource, callback) => {
        vuiModalService.showModal({
          template: require('./change-payment-source/change-payment-source.modal.html'),
          controller: require('./change-payment-source/change-payment-source.modal').default,
          inputs: {
            sources: sources,
            currentSource: currentSource
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.confirmation = (config, callback) => {
        vuiModalService.showModal({
          template: require('./confirmation/confirmation.modal.html'),
          controller: require('./confirmation/confirmation.modal').default,
          inputs: {
            config: config
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.confirmationRequestChanges = (details, callback) => {
        vuiModalService.showModal({
          template: require('./confirmation-request-changes/confirmation-request-changes.modal.html'),
          controller: require('./confirmation-request-changes/confirmation-request-changes.modal').default,
          inputs: {
            details: details
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.contactUs = (callback) => {
        vuiModalService.showModal({
          template: require('./contact-us/contact-us.modal.html'),
          controller: require('./contact-us/contact-us.modal').default,
        }).then(modal => modal.close
          .then(result => callback ? callback(result) : undefined));
      }

      self.cropImage = (source, title, onUpload, callback) => {
        vuiModalService.showModal({
          template: require('./crop-image/crop-image.modal.html'),
          controller: require('./crop-image/crop-image.modal').default,
          inputs: {
            source: source,
            title: title,
            onUpload: onUpload,
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.importBicycles = (groupId, onUpload, callback) => {
        vuiModalService.showModal({
          template: require('./import-bicycles/import-bicycles.modal.html'),
          controller: require('./import-bicycles/import-bicycles.modal').default,
          inputs: {
            groupId: groupId,
            onUpload: onUpload,
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.importBicyclesWarning = (bicycles, callback) => {
        vuiModalService.showModal({
          template: require('./import-bicycles-warning/import-bicycles-warning.modal.html'),
          controller: require('./import-bicycles-warning/import-bicycles-warning.modal').default,
          inputs: {
            bicycles: bicycles
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.info = (message, callback) => {
        vuiModalService.showModal({
          template: require('./info/info.modal.html'),
          controller: require('./info/info.modal').default,
          inputs: {
            message: message
          }
        }).then(modal => modal.close
          .then(result => callback ? callback(result) : undefined));
      }

      self.manageService = (services, callback) => {
        vuiModalService.showModal({
          template: require('./manage-service/manage-service.modal.html'),
          controller: require('./manage-service/manage-service.modal').default,
          inputs: {
            services: services
          }
        }).then(modal => modal.close
          .then(result => callback ? callback(result) : undefined));
      }

      self.addPaymentCard = (callback) => {
        vuiModalService.showModal({
          template: require('./payment/add-payment-card.modal.html'),
          controller: require('./payment/add-payment-card.modal').default
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.setMechanicRating = (request, callback) => {
        vuiModalService.showModal({
          template: require('./set-mechanic-rating/set-mechanic-rating.modal.html'),
          controller: require('./set-mechanic-rating/set-mechanic-rating.modal').default,
          inputs: {
            request: request
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.topUpBalance = (sources, currentSource, callback) => {
        vuiModalService.showModal({
          template: require('./top-up-balance/top-up-balance.modal.html'),
          controller: require('./top-up-balance/top-up-balance.modal').default,
          inputs: {
            sources: sources,
            currentSource: currentSource
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.editImages = (title, images, onUpload, callback) => {
        vuiModalService.showModal({
          template: require('./upload-images/upload-images.modal.html'),
          controller: require('./upload-images/upload-images.modal').default,
          inputs: {
            title: title,
            images: images,
            onUpload: onUpload
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.transferBicycle = (title, callback) => {
        vuiModalService.showModal({
          template: require('./transfer-bicycle/transfer-bicycle.modal.html'),
          controller: require('./transfer-bicycle/transfer-bicycle.modal').default,
          inputs: {
            title: title
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.unsatisfiedWithService = (contactUs, callback) => {
        vuiModalService.showModal({
          template: require('./unsatisfied-with-service/unsatisfied-with-service.modal.html'),
          controller: require('./unsatisfied-with-service/unsatisfied-with-service.modal').default,
          inputs: {
            contactUs: contactUs
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.searchDealer = (api, callback) => {
        vuiModalService.showModal({
          template: require('./search-dealer/search-dealer.modal.html'),
          controller: require('./search-dealer/search-dealer.modal').default,
          inputs: {
            api: api
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

    }

    return new Modals();
  };
}