import './page-size.component.scss';

export default {
  template: require('./page-size.component.html'),
  bindings: {
    pageSize: "=vuiPageSize",
    onChange: "=?vuiChange"
  },
  controller: function ($location) {
    'ngInject';

    const self = this;

    self.pageSizes = [10, 25, 50, 100];

    // depricated TODO
    if (!self.onChange) {
      self.onChange = (newValue) => {
        $location.search('size', newValue);
        $location.search('page', 0);
      }
    }

  }
};
