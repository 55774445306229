import angular from "angular";

const contactUs = function ($resource) {
  'ngInject';

  return $resource('/api/v1/fo/organisation-owner/:orgId/contact-us', {}, {
    send: {
      method: "POST",
      isArray: false
    }
  })
}

export default angular.module('vt-organisation-owner-resource.contact-us', [])
  .service('OrganisationOwnerContactUsApi', contactUs);
