import "./organisation-owner-organisations-list-item.component.scss";

export default {
  template: require('./organisation-owner-organisations-list-item.component.html'),
  bindings: {
    item: '<vuiItem'
  },
  controller: function () {
    'ngInject';
  }
};
