export default () => {
  'ngInject';

  return {
    restrict: 'A',
    link: (scope, element, attr) => {

      let receivedHeightFromChildNode = false;

      const collapseSection = (element, sectionHeight) => {
        const elementTransition = element[0].style.transition;
        element[0].style.transition = '';

        requestAnimationFrame(function () {
          element[0].style.height = sectionHeight + 'px';
          element[0].style.transition = elementTransition;
          scope.$digest();
        });
      }

      const setSize = () => {
        if (receivedHeightFromChildNode) {
          return;
        }

        const iFrameHeight = element[0].contentWindow.parent.document.body.offsetHeight;
        collapseSection(element, iFrameHeight);
      }

      element.on('load', setSize);

      window.addEventListener('message', event => {
        if (element && element[0] && element[0].src && event.origin.startsWith(new URL(element[0].src).origin)) {
          collapseSection(element, event.data.height);
          receivedHeightFromChildNode = true;
        }
      });

    }
  };
}
