import './checkbox.component.scss';

export default ['$timeout', ($timeout) => {
    return {
        restrict: 'E',
        replace: true,
        require: ['ngModel'],
        scope: {
            ngModel: "=ngModel",
            name: "@vuiName",
            disabled: "@disabled",
            onChange: '@vuiChange',
        },
        transclude: true,
        template: require('./checkbox.component.html'),
        link: (scope, element, attr) => {
            scope.setValue = (value) => {
                if (!scope.disabled) {
                    scope.ngModel = value;
                    scope.onChangeValue(scope.ngModel);
                }
            }

            scope.onChangeValue = (value) => {
                if (scope.onChange) {
                    scope.$parent.$eval(scope.onChange)
                }
            }
        }
    };
}]
