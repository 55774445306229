import "./organisation-owner-root.component.scss";

export default {
  template: require('./organisation-owner-root.component.html'),
  controller: function ($scope, $timeout, $location, $t, AuthService, ToggleLD, Notification,
                        OrganisationOwnerContactUsApi, vuiModals, $routeParams) {
    'ngInject';

    const self = $scope.$ctrl;

    self.auth = AuthService;
    self.params = $routeParams;
    self.homePath = '/organisation/' + self.params.orgId + '/service-bookings'

    self.submitContactUs = message => {
      ToggleLD.show();
      OrganisationOwnerContactUsApi.send({orgId: $routeParams.orgId}, {body: message},
        response => {
          const successMessage = $t('shared.modals.contact_us.sending_success_message');
          vuiModals.info(successMessage);
          ToggleLD.hide();
        },
        error => {
          ToggleLD.hide();
          Notification.error(error.data.message || $t('public.about.contact_us.send_message_error_message'));
        })
    }

    const checkUrl = () => {
      const path = '/organisation/' + $routeParams.orgId;
      if (path === $location.path()) {
        $location.url(path + '/service-bookings');
      }
    }

    checkUrl();

  }
};
