import './pagination.component.scss';

export default {
  template: require('./pagination.component.html'),
  bindings: {
    page: "=vuiPage",
    onChange: "=?vuiChange"
  },
  controller: function () {
    'ngInject';
  }
};
