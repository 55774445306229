export default function CurrentOrganisationProvider() {
  this.$get = (AuthService, $routeParams, OrganisationOwnerOrganisationsApi, $t, Notification) => {
    'ngInject';

    function CurrentOrganisationProvider() {

      const self = this;

      self.getType = callback => {
        if (self.organisation && self.organisation.id === $routeParams.orgId) {
          callback(self.organisation.type)
        } else {
          self.load(callback);
        }
      }

      self.load = callback => {
        if (!$routeParams.orgId) {
          callback();
          return;
        }

        OrganisationOwnerOrganisationsApi.getById({id: $routeParams.orgId},
          response => {
            self.organisation = response;
            callback(self.organisation.type);
          },
          error => Notification.error(error.data.message || $t('organisation_owner.select_organisation.loading_organisation_error_message')))
      }

      self.set = organisation => self.organisation = organisation;

      self.isFleet = () => self.organisation && self.organisation.type === 'FLEET';

      self.isBrand = () => self.organisation && self.organisation.type === 'BRAND';
    }

    return new CurrentOrganisationProvider();
  };
}