import angular from "angular";
import MarkerImage from "img/market-pin-dot.png";

import "./g-map.component.scss";

export default {
  template: require('./g-map.component.html'),
  bindings: {
    map: '=map'
  },
  controller: function ($timeout, $scope, $compile, $element, $routeParams, $t, gMapConfig) {
    'ngInject';

    const self = this;

    const init = () => {
      self.map.map = new window.google.maps.Map($element[0].children[0], gMapConfig);
      google.maps.event.addListener(self.map.map, 'click', e => {
        locations.forEach(location => {
          location.infoWindow.close();
          location.openCallback(false);
        });
      });
    };

    let locations = [];
    let existingShapes = [];

    self.map.addMarker = (area, options) => {
      const defaultMarkerOptions = {
        position: area.center,
        map: self.map.map,
        icon: MarkerImage
      };

      options = options || {};

      const markerOptions = angular.extend({}, defaultMarkerOptions, options.marker || {});
      const marker = new window.google.maps.Marker(markerOptions);

      const location = {
        key: area,
        marker: marker
      };

      locations.push(location);

      return location;
    }

    self.map.clear = () => {
      locations.filter(location => location.infoWindow)
        .forEach(location => location.infoWindow.close());
      locations
        .map(location => location.marker)
        .forEach(marker => marker.setMap(null));
      locations = [];

      existingShapes.forEach(shape => shape.setMap(null));
      existingShapes = [];
    }

    self.map.addBicycleInfoWindow = (location, options) => {
      if (options.infoWindow) {
        locations.filter(location => location.infoWindow)
          .forEach(location => location.infoWindow.close());

        const template =
          '<div class="vui-g-map__location-info">' +
          '   <div class="location-info__icon">' +
          '     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
          '       <path d="M14 6.66699C14 11.3337 8 15.3337 8 15.3337C8 15.3337 2 11.3337 2 6.66699C2 5.07569 2.63214 3.54957 3.75736 2.42435C4.88258 1.29913 6.4087 0.666992 8 0.666992C9.5913 0.666992 11.1174 1.29913 12.2426 2.42435C13.3679 3.54957 14 5.07569 14 6.66699Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>' +
          '       <path d="M8 8.66699C9.10457 8.66699 10 7.77156 10 6.66699C10 5.56242 9.10457 4.66699 8 4.66699C6.89543 4.66699 6 5.56242 6 6.66699C6 7.77156 6.89543 8.66699 8 8.66699Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>' +
          '     </svg>' +
          '   </div>' +
          '   <div class="location-info__content">' +
          options.infoWindow +
          '   </div>' +
          '</div>'

        const currentInfoWindow = new window.google.maps.InfoWindow({
          content: template,
          position: new google.maps.LatLng(location.marker.position.lat(), location.marker.position.lng())
        });
        location.infoWindow = currentInfoWindow;

        currentInfoWindow.open(self.map.map);
      }
    }

    self.map.addServiceProviderInfoWindow = (location, options) => {
      location.serviceProvider = options.serviceProvider;
      location.openCallback = options.openCallback;

      const template =
        '<div class="vui-g-map__service-provider-info">' +
        '  <div class="service-provider__avatar">' +
        '    <img src="{{serviceProvider.avatar}}" alt="Service Provider">' +
        '  </div>' +
        '  <div class="service-provider__content">' +
        '    <div class="title">' +
        '      <div class="title__name">' +
        '        {{serviceProvider.name}} {{serviceProvider.surname}}' +
        '      </div>' +
        '      <div class="title__rating" ng-if="serviceProvider.rating">' +
        '        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '          <path d="M8 1.30902L9.55835 6.10512L9.61448 6.27786H9.79611H14.839L10.7592 9.24202L10.6123 9.34878L10.6684 9.52153L12.2268 14.3176L8.14695 11.3535L8 11.2467L7.85305 11.3535L3.77325 14.3176L5.33159 9.52153L5.38772 9.34878L5.24078 9.24202L1.16097 6.27786H6.20389H6.38552L6.44165 6.10512L8 1.30902Z" fill="#FFF400" stroke="#B3B3B3" stroke-width="0.5"/>' +
        '        </svg>' +
        '        {{serviceProvider.rating}}' +
        '      </div>' +
        '    </div>' +
        '    <div class="category" ng-if="serviceProvider.mechanicCategory">' +
        '      {{ "shared.service_provider_categories." + serviceProvider.mechanicCategory.toLowerCase() | translate }}' +
        '    </div>' +
        '    <div class="book-service">' +
        '      <vui-button class="btn-sm btn-sm-primary"' +
        '                   href="' + options.link + '">' +
        '           {{ "shared.service_providers.favorite.book_btn" | translate }}' +
        '       </vui-button>' +
        '    </div>' +
        '  </div>' +
        '</div>';

      const infoWindowScope = $scope.$new();
      infoWindowScope.serviceProvider = options.serviceProvider;
      const linkFn = $compile(template);
      const infoWindow = linkFn(infoWindowScope);

      const marker = location.marker;

      const currentInfoWindow = new window.google.maps.InfoWindow({
        content: infoWindow[0],
        position: new google.maps.LatLng(marker.position.lat(), marker.position.lng())
      });
      location.infoWindow = currentInfoWindow;

      marker.addListener('click', () => {
        locations.forEach(location => {
          location.infoWindow.close();
          location.openCallback(false);
        });
        location.openCallback(true);
        currentInfoWindow.open(self.map.map);
      });
    }

    self.map.center = () => {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach(location => bounds.extend(location.marker.position));
      bounds.extend(new google.maps.LatLng(bounds.getSouthWest().lat() - 0.003, bounds.getSouthWest().lng() - .035));
      self.map.map.fitBounds(bounds);
      if (locations.length === 1) {
        self.map.map.setZoom(15);
      }
    }

    self.map.setLocation = (area, options) => {
      const location = self.map.addMarker(area, options);
      self.map.center();
      return location;
    };

    self.map.addCircle = (center, radius, options) => {
      const defaultOptions = {
        map: self.map.map,
        strokeColor: '#777777',
        fillColor: '#fff400',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.4
      };

      options = options || {};

      const circleOptions = angular.extend({}, defaultOptions, options || {});
      circleOptions.center = center;
      circleOptions.radius = radius;

      const circle = new window.google.maps.Circle(circleOptions);

      existingShapes.push(circle);

      return circle;
    };

    init();

  }
};
