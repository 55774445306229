import './cancelation-info.modal.scss';

export default function CancelationInfoModal($scope, $t, close, config, Notification) {
  'ngInject';

  $scope.cancelationInfo = config;

  $scope.mapReasonValue = reason => {
    if (reason) {
      return $t('shared.requests.cancelation_reason.' + reason);
    }

    return reason;
  }

  $scope.ok = (form) => {
    if (!$scope.cancelationInfo.reason) {
      Notification.error($t('shared.modals.cancelation_info.reason_required_error'));
      return;
    }

    if (form.$valid) {
      const result = {reason: $scope.cancelationInfo.reason};

      if (result.other === 'OTHER') {
        result.description = $scope.cancelationInfo.description;
      }

      close(result);
    }
  }

  $scope.close = () => {
    close(false);
  }
}