export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/organisation/:orgId/settings/profile', 'organisation.profile')
    .when('/organisation/:orgId/settings/services', 'organisation.services')
    .when('/organisation/:orgId/settings/referral-programs', 'organisation.referral-programs')
    .when('/organisation/:orgId/settings/payments', 'organisation.payments')
    .when('/organisation/:orgId/settings/transactions', 'organisation.transactions')
    .when('/organisation/:orgId/settings/transactions/:id', 'organisation.transaction-info')
    .when('/organisation/:orgId/settings/users', 'organisation.users')

    .within('organisation')

    .segment('profile', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.settings'
      }],
      template: '<organisation-owner-organisation-info-page></organisation-owner-organisation-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-organisation-info.module" */ "./organisation-info/organisation-owner-organisation-info.module");
      }
    })

    .segment('services', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.settings'
      }],
      template: '<organisation-owner-services-page></organisation-owner-services-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-services.module" */ "./services/organisation-owner-services.module");
      }
    })

    .segment('referral-programs', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.settings'
      }],
      template: '<organisation-owner-referral-programs-page></organisation-owner-referral-programs-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-referral-programs.module" */ "./referral-programs/organisation-owner-referral-programs.module");
      }
    })

    .segment('payments', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.settings'
      }],
      template: '<organisation-owner-payments-page></organisation-owner-payments-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-payments.module" */ "./payments/organisation-owner-payments.module");
      }
    })

    .segment('transactions', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.settings'
      }],
      template: '<organisation-owner-transactions-page></organisation-owner-transactions-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-transactions.module" */ "./transactions/organisation-owner-transactions.module");
      }
    })

    .segment('transaction-info', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'organisation_owner.breadcrumb.settings',
          url: '/organisation/:orgId/settings/transactions',
          params: {
            orgId: ':orgId'
          }
        },
        {
          label: 'organisation_owner.breadcrumb.transaction_details'
        }
      ],
      template: '<organisation-owner-transaction-info-page></organisation-owner-transaction-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-transaction-info.module" */ "./transaction-info/organisation-owner-transaction-info.module");
      }
    })

    .segment('users', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.settings'
      }],
      template: '<organisation-owner-organisation-managers-page></organisation-owner-organisation-managers-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-organisation-managers.module" */ "./managers/organisation-owner-organisation-managers.module");
      }
    });
}
