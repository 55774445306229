export default function ChangeRouteServiceProvider() {
  this.$get = ($location, $t, vuiModals) => {
    'ngInject';

    function ChangeRouteService() {

      const self = this;

      let countModals = 0;

      const buildNextUrl = next => {
        let nextUrl = next.$$route.originalPath;
        if (next.pathParams) {
          for (let key in next.pathParams) {
            nextUrl = nextUrl.replace(':' + key, next.pathParams[key]);
          }
        }

        return nextUrl;
      }

      self.setupLeaveMessage = (scope, message, callback) => {
        let locationUnsubscribeEvent = scope.$on("$routeChangeStart", (event, next, current) => {
          if (next === undefined) {
            return;
          }

          const nextUrl = buildNextUrl(next);

          if (nextUrl && callback && callback(nextUrl)) {
            return;
          }

          event.preventDefault();

          if (countModals === 0) {
            countModals++;

            const title = $t('shared.modals.unsaved_changes.title');
            vuiModals.confirmation({title: title, message: message}, response => {
              countModals--;

              if (response) {
                locationUnsubscribeEvent();
                for (const i in scope.$$listeners.$locationChangeStart) {
                  if (scope.$$listeners.$locationChangeStart[i] == locationUnsubscribeEvent) {
                    scope.$$listeners.$locationChangeStart.splice(i, 1);
                  }
                }
                locationUnsubscribeEvent = null;

                $location.url(nextUrl);
              }
            });
          }
        });
      };

    }

    return new ChangeRouteService();
  };
}