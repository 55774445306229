import "./organisation-type-restrictions.component.scss";

export default {
  template: require('./organisation-type-restrictions.component.html'),
  bindings: {
    type: '@vuiType',
    class: '@vuiClass'
  },
  transclude: true,
  controller: function (vuiOrganisation) {
    'ngInject';

    const self = this;

    vuiOrganisation.getType(type => self.match = self.type === type);

  }
};
