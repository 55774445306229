import angular from "angular";

import VtSharedModule from '../../../vt-shared/vt-shared.module';
import OrganisationOwnerOrganisationInfoComponent from "./organisation-info/organisation-info.component";
import ManagerPermitComponent from "./manager-permit/manager-permit.component";
import OrganisationTypeRestrictionsComponent from "./organisation-type-restrictions/organisation-type-restrictions.component";
import OrganisationOwnerOrganisationsListComponent
  from "./organisations-list/organisation-owner-organisations-list.component";
import OrganisationOwnerOrganisationsListItemComponent
  from "./organisations-list-item/organisation-owner-organisations-list-item.component";
import OrganisationOwnerUpdateOrganisationInfoComponent
  from "./update-organisation-info/update-organisation-info.component";
import OrganisationOwnerOrganisationsApi from '../../resources/organisations/organisation-owner-organisations.resource';
import CurrentOrganisationService from "../../services/organisation/current-organisation.service"
import OrganisationManagerPermissionsService from "../../services/permissions/organisation-manager-permissions.service"

export default angular.module("vt-organisation-owner-organisations-components.module", [
  VtSharedModule.name,
  OrganisationOwnerOrganisationsApi.name
])
  .component("vuiManagerPermit", ManagerPermitComponent)
  .component("vuiOrganisationTypeRestrictions", OrganisationTypeRestrictionsComponent)
  .component("vuiOrganisationOwnerOrganisationInfo", OrganisationOwnerOrganisationInfoComponent)
  .component("vuiOrganisationOwnerOrganisationsList", OrganisationOwnerOrganisationsListComponent)
  .component("vuiOrganisationOwnerOrganisationsListItem", OrganisationOwnerOrganisationsListItemComponent)
  .component("vuiOrganisationOwnerUpdateOrganisationInfo", OrganisationOwnerUpdateOrganisationInfoComponent)
  .provider("vuiOrganisation", CurrentOrganisationService)
  .provider("vuiOrganisationManagerPermissions", OrganisationManagerPermissionsService);
