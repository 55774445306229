import "./manager-permit.component.scss";

export default {
  template: require('./manager-permit.component.html'),
  bindings: {
    permission: '@vuiPermission',
    class: '@vuiClass'
  },
  transclude: true,
  controller: function (vuiOrganisationManagerPermissions) {
    'ngInject';

    const self = this;

    vuiOrganisationManagerPermissions.hasPermission(self.permission, permit => self.permit = permit)

  }
};
