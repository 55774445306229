import './transfer-bicycle.modal.scss';

export default function TransferBicycleModal($scope, title, close) {
  'ngInject';

  $scope.transfer = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    title: title
  };

  $scope.onChangePhoneNumber = (phone, isValid) => {
    $scope.transfer.phone = phone;
    $scope.transfer.isValidPhone = isValid;
  };

  $scope.ok = (form) => {
    if (form.$valid && $scope.transfer.isValidPhone) {
      close({
        name: $scope.transfer.name,
        surname: $scope.transfer.surname,
        email: $scope.transfer.email,
        phone: $scope.transfer.phone
      });
    }
  }

  $scope.close = () => {
    close(false);
  }
}