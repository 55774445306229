export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/organisation/:orgId/service-providers/dealers', 'organisation.dealers')
    .when('/organisation/:orgId/service-providers/search', 'organisation.search-service-providers')
    .when('/organisation/:orgId/service-providers/dealers/:id', 'organisation.dealers-info')
    .when('/organisation/:orgId/service-providers/search/:id', 'organisation.search-service-provider-info')
    .when('/organisation/:orgId/service-providers/:id', 'organisation.service-provider-info')

    .within('organisation')

    .segment('dealers', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.service_providers'
      }],
      template: '<organisation-owner-dealers-page></organisationOwnerDealersPage>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-dealers.module" */ "./dealers/organisation-owner-dealers.module");
      }
    })
    .segment('search-service-providers', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.service_providers',
      }],
      template: '<organisation-owner-search-service-providers-page></organisation-owner-search-service-providers-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-search-service-providers.module" */ "./search/organisation-owner-search-service-providers.module");
      }
    })
    .segment('service-provider-info', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'organisation_owner.breadcrumb.service_providers',
          url: '/organisation/:orgId/service-providers/search',
          params: {
            orgId: ':orgId'
          }
        },
        {
          label: 'organisation_owner.breadcrumb.mechanic_profile'
        }
      ],
      template: '<organisation-owner-service-provider-info-page></organisation-owner-service-provider-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-service-provider-info.module" */ "./info/organisation-owner-service-provider-info.module");
      }
    })
    .segment('dealers-info', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'organisation_owner.breadcrumb.service_providers',
          url: '/organisation/:orgId/service-providers/dealers',
          params: {
            orgId: ':orgId'
          }
        },
        {
          label: 'organisation_owner.breadcrumb.mechanic_profile'
        }
      ],
      template: '<organisation-owner-service-provider-info-page></organisation-owner-service-provider-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-service-provider-info.module" */ "./info/organisation-owner-service-provider-info.module");
      }
    })
    .segment('search-service-provider-info', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'organisation_owner.breadcrumb.service_providers',
          url: '/organisation/:orgId/service-providers/search',
          params: {
            orgId: ':orgId'
          }
        },
        {
          label: 'organisation_owner.breadcrumb.mechanic_profile'
        }
      ],
      template: '<organisation-owner-service-provider-info-page></organisation-owner-service-provider-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-service-provider-info.module" */ "./info/organisation-owner-service-provider-info.module");
      }
    });

}
