import "./organisation-owner-organisations-list.component.scss";

import DefaultImage from "img/ic_no_avatar.svg";

export default {
  template: require('./organisation-owner-organisations-list.component.html'),
  controller: function ($t, OrganisationOwnerOrganisationsApi, ToggleLD, Notification, AuthService, $location, $timeout) {
    'ngInject';

    const PAGE_SIZE = 10;

    const self = this;

    self.page = 0;
    self.showMore = false;
    self.organisations = [];

    AuthService.currentUser(user => self.user = user);

    const loadOrganisations = callback => {
      self.loading = true;
      OrganisationOwnerOrganisationsApi.getAll({
          page: self.page,
          size: PAGE_SIZE,
          name: self.search
        },
        response => {
          response.forEach(item => {
            if (!item.image) {
              item.image = {thumbnail: DefaultImage};
            }
          });

          callback(response)
        },
        error => {
          self.loading = false;
          Notification.error(error.data.message || $t('organisation_owner.select_organisation.loading_organisations_error_message'));
        })
    }

    self.loadMore = (callback) => {
      loadOrganisations(response => {
        self.organisations = self.organisations.concat(response);
        self.loading = false;
        self.showMore = response.length === PAGE_SIZE;
        self.page++;
      })
    }

    loadOrganisations(response => {
      if (response.length === 1) {
        $location.url("/organisation/" + response[0].id + "/service-bookings");
      } else {
        self.organisations = self.organisations.concat(response);
        self.loading = false;
        self.showMore = response.length === PAGE_SIZE;
        self.page++;
      }
    });

    let countOfCalls = 0;
    self.onChangeName = () => {
      countOfCalls++;
      $timeout(() => {
        countOfCalls--;
        if (countOfCalls === 0) {
          self.organisations = [];
          self.page = 0;
          self.showMore = false;
          loadOrganisations(response => {
            self.organisations = response;
            self.loading = false;
            self.showMore = response.length === PAGE_SIZE;
            self.page++;
          });
        }
      }, 250);
    }

  }
};
