import "./organisation-info.component.scss";

export default {
  template: require('./organisation-info.component.html'),
  bindings: {
    organisation: '=vuiOrganisation'
  },
  controller: function (vuiSideForm) {
    'ngInject';

    const self = this;

    self.onEdit = () => {
      vuiSideForm.open('update-organisation-info');
    }
  }
};
